interface Address {
  streetAddress: string
  addressLocality: string
  addressRegion: string
  postalCode: string
  addressCountry: string
}

interface FAQItem {
  label: string
  content: string
}

interface ServicePrice {
  name: string
  price: string
  currency: string
  description?: string
}

interface ArticleSchema {
  title: string
  publishedAt: string
  updatedAt: string
  author?: {
    name: string
    url?: string
  }
  cover: string | string[]
  description: string
}

interface JsonLdProps {
  name?: string
  url?: string
  description?: string
  logo?: string
  image?: string
  address?: Address
  faqs?: FAQItem[]
  serviceDetails?: {
    name: string
    description: string
    medicalSpecialty?: string
    prices?: ServicePrice[]
    availableService?: string[]
  }
  article?: ArticleSchema
}

export const useJsonLd = (props: JsonLdProps = {}) => {
  // const route = useRoute()
  const config = useRuntimeConfig()
  const { t } = useI18n()
  const localePath = useLocalePath()
  const { app } = useAppConfig()
  const { localizeString } = useLocalization()

  const defaultSocialLinks = [
    'https://www.linkedin.com/company/31332282',
    'https://www.facebook.com/shoebillhealth',
    'https://instagram.com/shoebillhealth',
  ]

  // Create a single schema with @graph
  const schema = {
    '@context': 'https://schema.org',
    '@graph': [
      {
        '@type': 'Organization',
        // '@id': `${config.public.baseUrl}/#organization`,
        name: props.name ?? t('app.title'),
        url: localePath(props.url ?? `${config.public.baseUrl}`),
        sameAs: defaultSocialLinks,
        logo: {
          '@type': 'ImageObject',
          url: props.logo ?? app.logo,
        },
        foundingDate: '2019',
        image: {
          '@type': 'ImageObject',
          url: props.image ?? app.ogImg,
        },
        description: props.description ?? t('app.description'),
        address: {
          '@type': 'PostalAddress',
          streetAddress: localizeString(
            '1/F, 562 Nathan Road',
            '旺角彌敦道562號1樓全層'
          ),
          addressLocality: localizeString('Mongkok', '旺角'),
          addressRegion: localizeString('Kowloon', '九龍'),
          postalCode: '852',
          addressCountry: 'HK',
        },
        contactPoint: {
          '@type': 'ContactPoint',
          contactType: 'customer support',
          telephone: '+852 5689 9175',
          email: 'service@shoebillhealth.com',
        },
        geo: {
          '@type': 'GeoCoordinates',
          latitude: '22.314707',
          longitude: '114.1679455',
        },
        hasMap: 'https://maps.app.goo.gl/3A89bcndu8KntX8M9',
        openingHoursSpecification: [
          {
            '@type': 'OpeningHoursSpecification',
            dayOfWeek: [
              'Monday',
              'Tuesday',
              'Wednesday',
              'Thursday',
              'Friday',
              'Saturday',
              'Sunday',
            ],
            opens: '10:00',
            closes: '19:00',
          },
        ],
        telephone: '+852 5689 9175',
      },
    ],
  }

  // Add FAQ schema if exists
  if (props.faqs?.length) {
    schema['@graph'].push({
      '@type': 'FAQPage',
      // '@id': `${config.public.baseUrl}${route.path}#faq`,
      mainEntity: props.faqs.map((faq) => ({
        '@type': 'Question',
        name: faq.label,
        acceptedAnswer: {
          '@type': 'Answer',
          text: faq.content,
        },
      })),
    })
  }
  // Add article schema if exists
  if (props.article) {
    schema['@graph'].push({
      '@type': 'NewsArticle',
      // '@id': `${config.public.baseUrl}${route.path}#article`,

      headline: props.article.title,
      image: Array.isArray(props.article.cover)
        ? props.article.cover
        : [props.article.cover ?? app.ogImg],
      datePublished: props.article.publishedAt,
      dateModified: props.article.updatedAt,
      // author: {
      //   '@type': 'Person',
      //   name: props.article.author?.name ?? t('app.title'),
      //   url: props.article.author?.url,
      // },
      // publisher: {
      //   '@id': `${config.public.baseUrl}/#organization`,
      // },
      description: props.description ?? t('app.description'),
    })
  }

  // Use useHead to set the JSON-LD schema
  useHead({
    script: [
      {
        type: 'application/ld+json',
        children: JSON.stringify(schema),
      },
    ],
  })

  return schema
}
